import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
import { ReactComponent as CarLeft } from "../../../assets/icons/car-left.svg";
import { ReactComponent as CarRight } from "../../../assets/icons/car-right.svg";
import { mainApi } from "../../../components/utils/main-api";
import ProductItem from "./product-item";
import Snackbar from "../../../components/snackbar/snackbar";

function AllProducts({ userData }) {
  // snackbar
  const [visibleSnack, setVisibleSnack] = useState(false);
  const [snackText, setSnackText] = useState("");
  const [snackStatus, setSnackStatus] = useState("");
  const snackOptions = (text, status) => {
    setVisibleSnack(true);
    setSnackText(text);
    setSnackStatus(status);
    setTimeout(() => {
      setVisibleSnack(false);
    }, 2000);
  };
  const [platforms, setPlatforms] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState();
  const [products, setProducts] = useState([]);

  const getPlatformsActions = () => {
    mainApi
      .getPlatforms()
      .then((userData) => {
        setPlatforms(userData);
      })
      .catch(() => {
        console.log("error");
      });
  };

  const getProductsActions = () => {
    mainApi
      .getProducts()
      .then((data) => {
        setProducts(data.data);
      })
      .catch(() => {
        console.log("error");
      });
  };

  useEffect(() => {
    getPlatformsActions();
    getProductsActions();
  }, []);

  const inputFileRef = useRef(null);
  const inputFileRef2 = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleUpload(file);
    }
  };

  const handleFileChange2 = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleUpload2(file);
    }
  };
  const handleClick = () => {
    inputFileRef.current.click();
  };
  const handleClick2 = () => {
    inputFileRef2.current.click();
  };

  const handleUpload = async (file) => {
    if (!file || !selectedPlatform) {
      snackOptions("Выберите файл и маркетплейс для загрузки", "error");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(
        `https://api-seller.bonusreturn.ru/api/v1/personal_area/upload-products/?platform=${selectedPlatform}`,
        {
          method: "POST",
          body: formData,
          headers: {
            "Api-Key": localStorage.getItem("token"),
          },
        }
      );

      if (response.ok) {
        const data = await response.json();

        snackOptions("Файл загружен успешно", "success");
      } else {
        snackOptions("Ошибка загрузки файла", "error");
      }
    } catch (error) {
      snackOptions("Ошибка загрузки файла", "error");
    } finally {
    }
  };

  const handleUpload2 = async (file) => {
    if (!file || !selectedPlatform) {
      snackOptions("Выберите файл и маркетплейс для загрузки", "error");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(
        `https://api-seller.bonusreturn.ru/api/v1/personal_area/upload-competitor-products/?platform=${selectedPlatform}`,
        {
          method: "POST",
          body: formData,
          headers: {
            "Api-Key": localStorage.getItem("token"),
          },
        }
      );

      if (response.ok) {
        const data = await response.json();

        snackOptions("Файл загружен успешно", "success");
      } else {
        snackOptions("Ошибка загрузки файла", "error");
      }
    } catch (error) {
      snackOptions("Ошибка загрузки файла", "error");
    } finally {
    }
  };

  // const getExamples = async () => {
  //   let headersList = {
  //     Accept: "*/*",

  //     "Api-Key": localStorage.getItem("token"),
  //   };

  //   let response = await fetch(
  //     "https://api-seller.bonusreturn.ru/api/v1/personal_area/upload-products/?example=true&platform=1",
  //     {
  //       method: "POST",
  //       headers: headersList,
  //     }
  //   );

  //   if (response.ok) {
  //     // Получаем бинарные данные
  //     let blob = await response.blob();

  //     // Создаем URL для файла
  //     let url = window.URL.createObjectURL(blob);

  //     // Создаем ссылку и симулируем клик
  //     let a = document.createElement("a");
  //     a.href = url;
  //     a.download = "example_file.xlsx"; // Укажите название и расширение файла
  //     document.body.appendChild(a);
  //     a.click();

  //     // Убираем ссылку
  //     document.body.removeChild(a);
  //     window.URL.revokeObjectURL(url);
  //   } else {
  //     console.error("Ошибка загрузки файла");
  //   }
  // };

  const [searchValue, setSearchValue] = useState();
  return (
    <>
      <div className="all_products_list container">
        <div className="all_products_list_top">
          <div className="all_products_list_top_buttons">
            <button>
              Все товары <span>{userData?.products_amount}</span>
            </button>

            <button onClick={handleClick}>Добавить свои товары</button>
            <button onClick={handleClick2}>Добавить товары конкурента</button>
            <input
              type="file"
              ref={inputFileRef}
              accept=".xlsx,.xls"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <input
              type="file"
              ref={inputFileRef2}
              accept=".xlsx,.xls"
              style={{ display: "none" }}
              onChange={handleFileChange2}
            />
          </div>
          <div className="cashback_stores_lists ">
            {platforms.map((img, index) => (
              <img
                className={
                  selectedPlatform == img.platform_id ? "selected_platform" : ""
                }
                key={index}
                src={img.image_url}
                alt={img.title}
                title={img.title}
                onClick={() => setSelectedPlatform(img.platform_id)}
              />
            ))}
          </div>
          <div className="all_products_list_top_actions">
            <p>Ожидание кэшбэка {products.length}</p>
            <p>Скачать Excel</p>
          </div>
        </div>
        <div className="all_products_list_top_second">
          <div>
            <p className="main_text">Общий на все товары Размер кэшбэка %</p>
            <input type="text" value="10" />
          </div>
          <div>
            <p className="main_text">Общий на все товары сумма промокода, ₽ </p>
            <input type="text" value="1000" />
          </div>
          <div>
            <p className="main_text">
              Общий на все товары Размер кэшбэка за отзыв %
            </p>
            <input type="text" value="100" />
          </div>
        </div>
        <div className="all_products_list_titles">
          <div className="home_header_search all_products_list_search">
            <input
              type="text"
              placeholder="Введите артикул или ссылку на товар"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <Search />
          </div>
          <div className="all_products_list_titles_list">
            <p>
              Размер <br />
              кэшбэка %
            </p>
            <p>
              Сумма <br />
              промокода
            </p>
            <p>
              Кэшбэк <br />
              за отзыв
            </p>
            <p>
              Ключевые <br />
              слова для отзыва,
              <br />
              через запятую
            </p>
            <p>Входящий отзыв</p>
          </div>
        </div>
        <div className="all_products_table">
          {products && products.length ? (
            products
              .filter(
                (product) =>
                  !searchValue || product.product_id.includes(searchValue)
              )
              .map((product) => (
                <ProductItem
                  item={product}
                  key={product.apply_id}
                  platforms={platforms}
                  getProductsActions={getProductsActions}
                />
              ))
          ) : (
            <p>Пусто</p>
          )}
        </div>
        {/* <div className="all_products_pagination">
        <CarLeft />
        <div className="all_products_pagination_items">
          <p>1</p>
          <p className="all_products_pagination_active">2</p>
          <p>3</p>
          <p>4</p>
          <p>5</p>
        </div>

        <CarRight />
      </div> */}
      </div>
      <Snackbar text={snackText} status={snackStatus} visible={visibleSnack} />
    </>
  );
}

export default AllProducts;
